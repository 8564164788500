import { action, observable } from 'mobx';

import RootStore from '../RootStore';
import { BaseFormModel, isRequired, displayName, isEmail } from '../BaseForm';

class LoginForm extends BaseFormModel {
  @observable
  @displayName('EMAIL')
  @isRequired()
  @isEmail()
  email = '';

  @observable
  @displayName('PASSWORD')
  @isRequired()
  password = '';

  @observable error = '';

  @action.bound
  async send(redirectUrl: string) {
    try {
      const data = {
        username: this.email,
        password: this.password,
      };

      this.error = '';
      //  jsc send url to redirect to
      await RootStore.user.login(data, redirectUrl);
    } catch (error) {
      this.error = 'error.form.incorrect';
    }
  }
}

export default LoginForm;
