import React, { Component } from 'react';
import { observer } from 'mobx-react';
// jsc
import AppRouter from '../../stores/AppRouter'; // Beispiel für einen relativen Pfad

import style from './ArchivePage.module.scss';
import ArchiveForm from './ArchiveSearchForm/ArchiveForm';
import Table from '../../components/Table/Table';
import config from './columnsConfig';
import ArchiveSearchForm from '../../stores/Forms/Archive/ArchiveSearchForm';
import ModalStore from '../../stores/ModalStore';
import ArchiveImagesViewModal from '../../components/Modals/ArchiveImagesViewModal/ArchiveImagesViewModal';
import Pagination from '../../components/Pagination/Pagination';

@observer
class ArchivePage extends Component {
  store = new ArchiveSearchForm();

  handleOpenImages = (id: string) => async () => {
    await this.store.getImages(id);
    await ModalStore.showModal(ArchiveImagesViewModal, {
      id,
      loading: this.store.loading,
      archive: this.store.archive.find(item => item.id === id),
      images: this.store.images,
    });
  };

  async componentDidMount() {
    // jsc
    const params = AppRouter.getSearchParams(); // Hole die Parameter aus der URL
    if (params) {
      this.store.setFormData(params); // Übergib die Parameter an den Formular-Store
    }

    await this.store.onSearch();
  }

  onChangePage = (page: number) => this.store.changePage(page);

  render() {
    const { archive, isEmptyArchiveSearchResult, loading } = this.store;
    const { page, pageCount } = this.store;

    return (
      <main className={style.main}>
        <ArchiveForm form={this.store} />
        <Table
          data={archive}
          columns={config({ onOpenImages: this.handleOpenImages })}
          noDataText={
            !isEmptyArchiveSearchResult
              ? 'table.archive.noDataText'
              : 'table.archive.isEmptyArchiveSearchResult'
          }
          loading={loading}
        />

        {this.store.orders?.length > 0 && (
          <Pagination
            className={style.pagination}
            pageCount={pageCount}
            page={page}
            onPageClick={data => this.onChangePage(data)}
          />
        )}
      </main>
    );
  }
}

export default ArchivePage;
