import React, { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';

import { ROUTES } from './routes';

import { Roles } from 'helpers/roles';

const { user } = RootStore;

interface PrivateRouterProps {
  component: ComponentType<any>;
  roles?: Roles[];
  computedMatch?: any;
  [name: string]: unknown;
}

@observer
class PrivateRouter extends React.Component<PrivateRouterProps> {
  render() {
    const { component: Component, roles, computedMatch, ...rest } = this.props;
    const key =
      computedMatch && computedMatch.params && computedMatch.params.id;
    const access =
      user.authenticated &&
      (!roles || roles.some(role => RootStore.user.roles.includes(role)));

    return (
      <Route
        {...rest}
        render={props =>
          access ? (
            <Component key={key} {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.login, // login route
                state: { from: props.location }, // jsc save actual target URL with params
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRouter;
