import { action, computed, observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import { TokensResponse, UserAuthFormData } from 'http/Api/User/types';
import httpFacade from 'http/httpFacade';
import { ROUTES } from 'routes/routes';

import router from 'stores/AppRouter';
import RootStore from 'stores/RootStore';
import StorageStore from 'stores/StorageStore';

import { JWT, Tokens } from './types';

import { getDefaultRouteByRole, Roles } from 'helpers/roles';
import { popup } from 'stores/Popup';
import { includes } from 'helpers/array';

export const REFRESH = 'refresh';
export const ACCESS_TOKEN_ACCESSOR = 'access_token';
export const REFRESH_TOKEN_ACCESSOR = 'refresh_token';

class UserStore {
  static clearUserData() {
    localStorage.removeItem(REFRESH);
    localStorage.removeItem(ACCESS_TOKEN_ACCESSOR);
    localStorage.removeItem(REFRESH_TOKEN_ACCESSOR);
  }

  @observable id: string;
  @observable username: string | null;
  @observable roles: Roles[] = [];
  @observable notification: string;

  appToken = Date.now().toString();

  @action
  init() {
    const { accessToken } = this.getAuthTokens();

    if (accessToken) {
      const authData = jwtDecode<JWT>(accessToken);
      const { socket, sampleLoaderService } = RootStore;

      this.id = authData.userId;
      this.username = authData.user_name;
      this.roles = authData.scope;

      sampleLoaderService.connect();
      socket.connect(this.getAuthTokens, this.refreshTokens);
    }

    StorageStore.subscribe(this.storageListener);
  }

  @computed
  get authenticated() {
    return !!this.username;
  }

  setAuthTokens = (tokens: TokensResponse): void => {
    localStorage.setItem(ACCESS_TOKEN_ACCESSOR, tokens.access_token);
    localStorage.setItem(REFRESH_TOKEN_ACCESSOR, tokens.refresh_token);
  };

  getAuthTokens = (): Tokens => ({
    accessToken: localStorage.getItem(ACCESS_TOKEN_ACCESSOR),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_ACCESSOR),
  });

  refreshTokens = async (refreshToken: string): Promise<TokensResponse> => {
    try {
      const { data } = await httpFacade.user.refreshToken(refreshToken);
      this.setAuthTokens(data);

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  @action.bound
  async login(
    { username, password }: UserAuthFormData,
    redirectUrl?: string, // jsc Add redirectUrl as an optional parameter
  ) {
    try {
      const response = await httpFacade.user.login({ username, password });

      if (typeof response !== 'undefined') {
        this.setAuthTokens(response.data);
      }

      this.init();

      localStorage.setItem(REFRESH, this.appToken);

      if (redirectUrl && redirectUrl.includes('/archive/orders')) {
        router.push(redirectUrl); // jsc redirect to orders rul
      } else {
        router.push(getDefaultRouteByRole());
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  logout() {
    let tokenExpired = false;
    this.username = null;
    this.roles = [];

    const { socket, sampleLoaderService } = RootStore;

    localStorage.setItem(REFRESH, this.appToken);

    const tokens = this.getAuthTokens();
    if (tokens.accessToken) {
      tokenExpired = true;
    }
    UserStore.clearUserData();
    if (tokenExpired) {
      RootStore.fetchConfig();
    }

    popup.dismiss();
    socket.disconnect();
    sampleLoaderService.disconnect();

    router.push(ROUTES.login);
  }

  @action.bound
  private storageListener(event) {
    if (
      event.key === REFRESH &&
      !includes([event.oldValue, event.newValue], this.appToken)
    ) {
      window.location.reload();
    }
  }
}

export default UserStore;
