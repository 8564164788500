import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
// jsc
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';
import PasswordField from 'components/Form/Fields/Password/PasswordField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import AppLink from 'components/AppLink/AppLink';
import Help from 'components/Help/Help';
import AppLogo from 'components/AppLogo/AppLogo';

import LoginForm from 'stores/Forms/LoginForm';

import { ROUTES } from 'routes/routes';
import { setUrlParamsToObject } from 'stores/utils';
import BrowserInfo from './BrowserInfo';

// jsc Relaxed location state, allowing any shape or undefined
interface LocationState {
  from?: { pathname: string; search: string } | undefined;
}

// jsc Extend the props of the LoginPage component using RouteComponentProps
@observer
class LoginPage extends React.Component<RouteComponentProps> {
  form = new LoginForm();
  FormControl = bindFormControl(this.form);

  closePopup = () => {
    this.form.error = '';
  };

  submit = async event => {
    event.preventDefault();

    // Access the location state safely and provide a default value
    const { from } = (this.props.location.state as LocationState) || {
      from: { pathname: '/' },
    };

    const redirectUrl = from ? from.pathname + (from.search || '') : '/';

    // Call the send method with the redirectUrl
    await this.form.send(redirectUrl);
  };

  componentDidMount() {
    setUrlParamsToObject(this.form);
  }

  render() {
    const FormControl = this.FormControl;
    const { error } = this.form;

    return (
      <>
        <section className={'login__section'}>
          <div className={'login__logo_wrapper'}>
            <AppLogo />
          </div>

          <div className={'login__form_wrapper'}>
            <form onSubmit={this.submit} name="login-form">
              <h3 className={'login__title'}>
                <FormattedMessage id="page.login.title" />
              </h3>

              <div className={'login__form_row'}>
                <FormControl
                  name="email"
                  render={props => <TextField {...props} />}
                />
              </div>

              <div className={'login__form_row'}>
                <FormControl
                  name="password"
                  render={props => <PasswordField {...props} />}
                />
              </div>

              <div className={'login__btn_wrapper'}>
                <Button
                  className={'login__btn login__btn_submit'}
                  type="submit"
                  disabled={!this.form.isFormValid}
                >
                  <FormattedMessage id="button.login" />
                </Button>
              </div>
            </form>
          </div>

          <div className={'login__toggle'}>
            <div className={'login__toggle_text'}>
              <FormattedMessage id="page.login.note" />
            </div>

            <div>
              <AppLink to={ROUTES.recover} className={'login__link'}>
                <FormattedMessage id="page.login.link" />
              </AppLink>
            </div>
          </div>

          {Boolean(error) && (
            <Help
              message={error}
              onClose={this.closePopup}
              className={'login__popup'}
            />
          )}
        </section>

        <BrowserInfo />
      </>
    );
  }
}

// jsc Wrap the LoginPage with withRouter to inject routing props
export default withRouter(LoginPage);
